/* eslint-disable */

import React from 'react';
import Loading from 'boss-components/Loading';
import loadable from '../utils/loadable';

export const BlankPage = loadable(() =>
  import('./Pages/BlankPage'), {
    fallback: <Loading />,
  });

export const DashboardPage = loadable(() =>
  import('./Pages/Dashboard'), {
    fallback: <Loading />,
  });

export const Form = loadable(() =>
  import('./Pages/Forms/ReduxForm'), {
    fallback: <Loading />,
  });

export const Table = loadable(() =>
  import('./Pages/Table/BasicTable'), {
    fallback: <Loading />,
  });

  export const Member = loadable(() =>
  import('./Pages/member/basicTable'), {
    fallback: <Loading />,
  });

  export const Staff = loadable(() =>
  import('./Pages/Staff/basicTable'), {
    fallback: <Loading />,
  });

  

  // export const AddStaff=loadable(()=>
  // import("./Pages/Staff/addStaff"),{
  //   fallback:<Loading />
  // });
  

  export const Plan = loadable(() =>
  import('./Pages/Plans/basicTable'), {
    fallback: <Loading />,
  });

  export const Attendance = loadable(() =>
  import('./Pages/Attendance/basicTable'), {
    fallback: <Loading />,
  });

  export const Transaction = loadable(() =>
  import('./Pages/Transaction/basicTable'), {
    fallback: <Loading />,
  });

export const Login = loadable(() =>
  import('./Pages/Users/Login'), {
    fallback: <Loading />,
  });

export const LoginDedicated = loadable(() =>
  import('./Pages/Standalone/LoginDedicated'), {
    fallback: <Loading />,
  });

export const Register = loadable(() =>
  import('./Pages/Users/Register'), {
    fallback: <Loading />,
  });

export const ResetPassword = loadable(() =>
  import('./Pages/Users/ResetPassword'), {
    fallback: <Loading />,
  });

export const NotFound = loadable(() =>
  import('./NotFound/NotFound'), {
  fallback: <Loading />,
});
export const NotFoundDedicated = loadable(() =>
  import('./Pages/Standalone/NotFoundDedicated'), {
    fallback: <Loading />,
  });

export const Error = loadable(() =>
  import('./Pages/Error'), {
    fallback: <Loading />,
  });

export const Maintenance = loadable(() =>
  import('./Pages/Maintenance'), {
    fallback: <Loading />,
  });

export const ComingSoon = loadable(() =>
  import('./Pages/ComingSoon'), {
    fallback: <Loading />,
  });

export const Parent = loadable(() =>
  import('./Parent'), {
    fallback: <Loading />,
  });

  export const AddStaff = loadable(()=>
  import('./AddPages/addStaff'),{
    fallback: <Loading />,
  }
  ) 

  export const AddMember = loadable(()=>
  import('./AddPages/addMember'),{
    fallback: <Loading />,
  }
  ) 

  export const AddPlans = loadable(()=>
  import('./AddPages/addPlan'),{
    fallback: <Loading />,
  }
  ) 

  export const AddExpense = loadable(()=>
  import('./AddPages/addExpense'),{
    fallback: <Loading />,
  }
  ) 

  export const AddRegister= loadable(()=>
  import('./AddPages/addRegistration'),{
    fallback: <Loading />,
  }
  ) 

  export const AddAttendence= loadable(()=>
  import('./AddPages/addAttendance'),{
    fallback: <Loading />,
  }
  ) 

  export const ViewMembers= loadable(()=>
  import('./AddPages/viewMembers'),{
    fallback: <Loading />,
  }
  ) 

  
  export const ViewUpdateStaff = loadable(()=>
  import('./viewUpdateStaff/updateStaff'),{
    fallback: <Loading />,
  }
  ) 

  export const UpdateExpense = loadable(()=>
  import('./viewUpdateExpense/updateExpense'),{
    fallback: <Loading />,
  }
  ) 

  export const UpdatePlans = loadable(()=>
  import('./viewUpdatePlan/updatePlan'),{
    fallback: <Loading />,
  }
  ) 

  export const ViewUpdateMember = loadable(()=>
  import('./viewUpdateMember/updateMember'),{
    fallback: <Loading />,
  }
  ) 