
const userJSON = localStorage.getItem('user');
const user = JSON.parse(userJSON);

let item = '';
if (user && user.length > 0) {
   item = user[0].role;
} else {
  console.log("User is null");
}


let navigationItems = [];

if (item === 'admin') {
navigationItems = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    icon: 'grid_view',
    linkParent: '/app',
  },
  {
    key: 'staff',
    name: 'Staff',
    icon: 'account_box',
    linkParent: '/app/staff',
  },
  {
    key: 'member',
    name: 'Members',
    icon: 'group',
    linkParent: '/app/members',
  },
  {
    key: 'plans',
    name: 'Plans',
    icon: 'card_membership',
    linkParent: '/app/plan',
  },
  {
    key: 'attendance',
    name: 'Attendance',
    icon: 'event_available',
    linkParent: '/app',
  },
  {
    key: 'transaction',
    name: 'Transactions',
    icon: 'receipt_long',
    linkParent: '/app/transaction',
  },
  {
    key: 'expenses',
    name: 'Expenses',
    icon: 'exposure',
    linkParent: '/app/expenses',
  },
  {
    key: 'logout',
    name: 'Logout',
    icon: 'logout',
    linkParent: '/login',
  },
];
} else if (item === 'staff') {
navigationItems = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    icon: 'settings_system_daydream',
    linkParent: '/app',
  },
  {
    key: 'member',
    name: 'Members',
    icon: 'account_box',
    linkParent: '/app/members',
  },
  {
    key: 'attendance',
    name: 'Attendance',
    icon: 'library_books',
    linkParent: '/app',
  },
  {
    key: 'transaction',
    name: 'Transactions',
    icon: 'video_label',
    linkParent: '/app/transaction',
  },
  {
    key: 'logout',
    name: 'Logout',
    icon: 'build',
    linkParent: '/login',

  },
];
}

module.exports = navigationItems;


