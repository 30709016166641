import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    const userLogIn = localStorage.getItem("user");

    if (userLogIn === null) {
      history.push("/login"); 
    }
  }, [history]);

  return <>{children}</>; 
};

export default AuthGuard;
