module.exports = {
  dashboard: '/app',
  login: '/login',
  market: '#',
  email: '#',
  profile: '#',
  calendar: '#',
  twitter: '#',
  github: '#',
  pinterest: '#',
  linkedin: '#'
};
