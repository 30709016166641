// import avatarApi from '../images/avatars';
// const dummyContents = {
//   user: {
//     name: 'John Doe',
//     title: 'Administrator',
//     avatar: avatarApi[6],
//     status: 'online'
//   },
//   text: {
//     title: 'Lorem ipsum',
//     subtitle: 'Ut a lorem eu odio cursus laoreet.',
//     sentences: 'Donec lacus sem, scelerisque sed ligula nec, iaculis porttitor mauris.',
//     paragraph: 'Sed rutrum augue libero, id faucibus quam aliquet sed. Phasellus interdum orci quam, volutpat ornare eros rhoncus sed. Donec vestibulum leo a auctor convallis. In dignissim consectetur molestie. Vivamus interdum tempor dui, nec posuere augue consequat sit amet. Suspendisse quis semper quam. Nullam nec neque sem.',
//     date: 'Jan 9, 2018'
//   }
// };

// export default dummyContents;


import avatarApi from '../images/avatars';
import constant from '../../../Constant';

const userJSON = localStorage.getItem('user');
const user = JSON.parse(userJSON);

let role = ''; 
let name = ''; 
let idProofImage = ''; 

if (user && user[0]) {
  role = user[0].role;
  name = user[0].name;
  idProofImage = user[0].id_proof_image;
  console.log(role);
  console.log(name);
  console.log("idProofImage  ",idProofImage);
} else {
  console.log("User data is null or undefined.");
}


const dummyContents = {
  user: {
    name: name,
    title: role,
    // avatar: avatarApi[6],

    // avatar: `http://localhost:3001/asset/staff/profilePic/1684941132214avatar5.png`,

    avatar: `${constant.baseurl}/asset/staff/profilePic/`+idProofImage,
    status: 'online'
  },
  text: {
    title: 'Lorem ipsum',
    subtitle: 'Ut a lorem eu odio cursus laoreet.',
    sentences: 'Donec lacus sem, scelerisque sed ligula nec, iaculis porttitor mauris.',
    paragraph: 'Sed rutrum augue libero, id faucibus quam aliquet sed. Phasellus interdum orci quam, volutpat ornare eros rhoncus sed. Donec vestibulum leo a auctor convallis. In dignissim consectetur molestie. Vivamus interdum tempor dui, nec posuere augue consequat sit amet. Suspendisse quis semper quam. Nullam nec neque sem.',
    date: 'Jan 9, 2018'
  }
};

export default dummyContents;
