import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AllInclusive from "@material-ui/icons/AllInclusive";
import Brightness5 from "@material-ui/icons/Brightness5";
import People from "@material-ui/icons/People";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { TextFieldRedux, CheckboxRedux } from "./ReduxFormMUI";
import { ContentDivider } from "../Divider";
import PapperBlock from "../PapperBlock/PapperBlock";
import styles from "./user-jss";
import backendApi from "../../../helper";
import constant from "../../../Constant";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import {  Redirect } from "react-router-dom";


// const required = (value) => (value === null ? "Required" : undefined);
// const email = (value) =>
//   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
//     ? "Invalid email"
//     : undefined;

// validation functions
const required = (value) => (value === null ? "Required" : undefined);
const mobile = (value) => {
  const mobileNumberPattern = /^\d{10}$/;
  if (!mobileNumberPattern.test(value)) {
    return "Invalid mobile number. Please enter exactly 10 digits.";
  }
  return undefined;
};

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function LoginFormV3(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const [mobile_no, setMobile] = useState("");
  const [password, setPassword] = useState("");

  // const [loggedIn, setLoggedIn] = useState(false); // Track login state

  const handleMobileChange = (e) => {
    // Update the mobile state when the input field changes
    setMobile(e.target.value);
  };

  const handlePasswordChange = (e) => {
    // Update the password state when the input field changes
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginSubmit = () => {
    const payload = { mobile_no: mobile_no, password: password};
    // const url = "http://localhost:3001/login";
    const url = `${constant.baseurl}login`
    console.log("url",url);

    backendApi(url, payload)
      .then((data) => {
        if (data.error === false) {
        localStorage.setItem("user", JSON.stringify(data.data));
        // setLoggedIn(true);
          window.location.href = "/app";
        } else {
          console.log(data.error);
          alert("Login failed");
          localStorage.removeItem("user");
        }
        console.log("Response data:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("login failed");
        setError(true);
      });
  };

  // useEffect(() => {
  //   const baseurl = constant.baseurl;
  //   console.log("baseurl===", baseurl);
  //   const timezone = constant.timezone;
  //   console.log("timezone===", timezone);

  //   const url = `${constant.baseurl}login`
  //   console.log(url);
  // },[]);


   // Check if the user is already logged in
   const isAuthenticated = !!localStorage.getItem("user")

   // Redirect to the dashboard if the user is already authenticated
   if (isAuthenticated) {
     return <Redirect to="/app" />;
    }
    
    const { classes, pristine, submitting } = props;

  return (
    <div className={classes.formWrap}>
      <PapperBlock whiteBg title="Login" icon="ion-md-contact" desc="" sx={{marginTop:'15px'}}>
        <form>
          <div>
            <FormControl className={classes.formControl}>
              <Field
                name="mobileNumber"
                component={TextFieldRedux}
                placeholder="Your Mobile Number"
                label="Your Mobile Number"
                required
                validate={[required, mobile]}
                className={classes.field}
                onChange={handleMobileChange}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <Field
                name="password"
                component={TextFieldRedux}
                type={showPassword ? "text" : "password"}
                label="Your Password"
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                validate={required}
                className={classes.field}
              />
            </FormControl>
          </div>
          <div className={classes.btnArea} style={{marginBottom:'20px'}}>
            {/* <FormControlLabel
              control={<Field name="checkbox" component={CheckboxRedux} />}
              label="Remember"
            /> */}
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleLoginSubmit}
            >
              Continue
              <ArrowForward
                className={classNames(classes.rightIcon, classes.iconSmall)}
                disabled={submitting || pristine}
              />
            </Button>
          </div>
          {/* <ContentDivider content="OR" />
          <div className={classNames(classes.socmedBtnArea, classes.btnArea)}>
            <Button
              variant="contained"
              size="small"
              className={classes.redBtn}
              type="button"
            >
              <AllInclusive
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              Socmed 1
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.blueBtn}
              type="button"
            >
              <Brightness5
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              Socmed 2
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.cyanBtn}
              type="button"
            >
              <People
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              Socmed 3
            </Button>
          </div>
          <div className={classes.footer}>
            Cannot Login?
            <Button
              size="small"
              component={LinkBtn}
              to="/reset-password"
              color="secondary"
              className={classes.button}
            >
              Forgot Password
            </Button>
            |{" "}
            <Button
              size="small"
              component={LinkBtn}
              to="/register"
              color="secondary"
              className={classes.button}
            >
              Register
            </Button>
          </div> */}
        </form>
      </PapperBlock>
    </div>
  );
}

LoginFormV3.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const LoginFormReduxed = reduxForm({
  form: "loginForm3",
  enableReinitialize: true,
})(LoginFormV3);

const FormInit = connect((state) => ({
  initialValues: state.login.usersLogin,
}))(LoginFormReduxed);

export default withStyles(styles)(FormInit);