// import React, { useContext } from 'react';
// import { PropTypes } from 'prop-types';
// import { Switch, Route } from 'react-router-dom';
// import Dashboard from '../Templates/Dashboard';
// import { ThemeContext } from './ThemeWrapper';
// import {
//   Parent,
//   DashboardPage,
//   BlankPage,
//   Form,
//   Table,
//   Member,
//   Staff,
//   Plan,
//   Attendance,
//   Transaction,
//   Error,
//   NotFound
// } from '../pageListAsync';

// function Application(props) {
//   const { history } = props;
//   const changeMode = useContext(ThemeContext);
//   return (
//     <Dashboard history={history} changeMode={changeMode}>
//       <Switch>
//         <Route exact path="/app" component={DashboardPage} />
//         <Route exact path="/app/blank-page" component={BlankPage} />
//         {/* <Route path="/app" component={DashboardPage} /> */}
//         <Route path="/app/pages/form" component={Form} />
//         <Route path="/app/expenses" component={Table} />
//         <Route path="/app/members" component={Member} />
//         <Route path="/app/staff" component={Staff} />
//         <Route path="/app/plan" component={Plan} />
//         <Route path="/app/attendance" component={Attendance} />
//         <Route path="/app/transaction" component={Transaction} />
//         {/* <Route path="/app/pages/not-found" component={NotFound} /> */}
//         {/* <Route path="/app/pages/error" component={Error} /> */}
//         {/* <Route exact path="/app/pages" component={Parent} /> */}
//         {/* <Route component={NotFound} /> */}
//       </Switch>
//     </Dashboard>
//   );
// }

// Application.propTypes = {
//   history: PropTypes.object.isRequired,
// };

// export default Application;


import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import AuthGuard from '../../../app/components/Forms/authForm';
import {
  Parent,
  DashboardPage,
  BlankPage,
  Form,
  Table,
  Member,
  Staff,
  Plan,
  Attendance,
  Transaction,
  Login,
  Error,
  NotFound,
 

 
 
 
} from '../pageListAsync';
// import AddStaff from '../AddStaff/addStaff';

function Application(props) {
  const { history } = props;
  const changeMode = useContext(ThemeContext);
  return (
    <Dashboard history={history} changeMode={changeMode}>
      <AuthGuard>
      <Switch>
        <Route exact path="/app" component={DashboardPage} />
        <Route exact path="/app/blank-page" component={BlankPage} />
        <Route path="/app/pages/form" component={Form} />
        <Route path="/app/expenses" component={Table} />
        <Route path="/app/members" component={Member} />
        <Route path="/app/staff" component={Staff} />
        {/* <Route path="/addStaff" component={AddStaff}/> */}
        {/* <Route path="app/addStaff" component={AddStaff}/> */}
        <Route path="/app/plan" component={Plan} />
        <Route path="/app/attendance" component={Attendance} />
        <Route path="/app/transaction" component={Transaction} />
        {/* <Route exact path="/" component={Login} /> */}
        {/* <Route path="/app/pages/not-found" component={NotFound} /> */}
        {/* <Route path="/app/pages/error" component={Error} /> */}
        {/* <Route exact path="/app/pages" component={Parent} /> */}
        {/* <Route component={NotFound} /> */}
        <Route path="/app/" />
      </Switch>
      </AuthGuard>
    </Dashboard>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Application;
