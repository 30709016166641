import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Outer from '../Templates/Outer';
import {
  Login,
  Register,
  ResetPassword,
  Maintenance,
  ComingSoon,
  NotFound,
  AddStaff,
  AddMember,
  AddPlans,
  AddExpense,
  AddRegister,
  AddAttendence,
  ViewMembers,
  ViewUpdateStaff,
  UpdateExpense,
  UpdatePlans,
  ViewUpdateMember
} from '../pageListAsync';

function Auth() {
  return (
    <Outer>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/coming-soon" component={ComingSoon} />
        <Route path="/addStaff" component={AddStaff}/>
        <Route path="/addMember" component={AddMember}/>
        <Route path="/addPlans" component={AddPlans}/>
        <Route path="/addExpense" component={AddExpense}/>
        <Route path="/addRegistration" component={AddRegister}/>
        <Route path="/addAttendence" component={AddAttendence}/>
        <Route path="/viewMember" component={ViewMembers}/>
        <Route path="/viewSingleStaff" component={ViewUpdateStaff}/>
        <Route path="/viewSingleExpense" component={UpdateExpense}/>
        <Route path="/viewSingleMember" component={ViewUpdateMember}/>
        <Route path="/viewSinglePlan" component={UpdatePlans}/>
        <Route component={NotFound} />
      </Switch>
    </Outer>
  );
}

export default Auth;
