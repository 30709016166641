import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'boss-api/dummy/brand';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Type from 'boss-styles/Typography.scss';
import ArrowForward from '@material-ui/icons/ArrowForward';
import logo from 'boss-images/logo.svg';
import { LoginForm } from 'boss-components';
import styles from 'boss-components/Forms/user-jss';
import { Button } from '@mui/material';
import background from "../../../../public/images/avatars/loginBack.svg";
import banner from "../../../../public/images/avatars/gymbanner.jpg";

function Login(props) {
  const [valueForm, setValueForm] = useState(null);

  const submitForm = values => {
    setTimeout(() => {
      setValueForm(values);
      console.log(`You submitted:\n\n${valueForm}`);
      window.location.href = '/app';
    }, 500); // simulate server latency
  };

  const openNewWindow = () => {
    try {
      console.log('Button clicked');
      const width = 1020;
      const height = 580;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;
      
      window.open('/addAttendence', 'newWindow', `width=${width}, height=${height}, left=${left}, top=${top}`);
    } catch (error) {
      console.error('Error opening new window:', error);
    }
  };
  const title = brand.name + ' - Login Version';
  const description = brand.desc;
  const { classes } = props;
  return (
    <div className={classes.root} >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
      </Helmet>
      <div className={classes.container}  style={{ backgroundImage: `url('https://img.freepik.com/free-photo/gym-with-dumbbells-floor_1340-37013.jpg?t=st=1696223982~exp=1696227582~hmac=63b2ce1f4d1863bf7c3e799bb3ec171713814d1a896810a9a9fa18693cecae4a&w=740')`, backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',height:"100vh" }}>
        <Grid container  direction="row" justifyContent="center" style={{height:"423px"}}>
          <Grid item container justifyContent="center" alignItems="center" spacing={0} className={classes.loginWrap}>
            <Hidden smDown>
              <Grid item md={6} className={classes.welcomeWrap}>
                {/* Welcome Login */}
                <div className={classes.welcome} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"baseline"}}>
                  <div className={classes.welcomeContent}>
                    <div className={classes.brand}>
                      <img src={logo} alt={brand.name} />
                      <h3>{brand.name}</h3>
                    </div>
                    <Typography variant="h3">
                      <span className={Type.light}>Hello there,</span>
                    </Typography>
                    <Typography variant="h6" className={classes.brandText}>
                      <span className={Type.regular}>
                        welcome to {' '}                     
                        {brand.name}
                      </span>
                    </Typography>
                  
                  </div>
                 
                  <ArrowForward className={classes.decoBottom} />
                  <div style={{marginTop:"30px"}}>
                    <Button variant="contained" style={{background:"#689E38"}} onClick={openNewWindow}>Add Attendence</Button>
                    {/* <button onClick={openNewWindow}>Add Attendence</button> */}
                </div>                
                </div>
                
              </Grid>
            </Hidden>
            <Grid item md={6} sm={8} xs={11} style={{height:'375px'}}>
              {/* ----------------------------------------------------------------------*/}
              {/* Load Login Form */}
              <LoginForm onSubmit={(values) => submitForm(values)} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
